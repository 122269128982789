export default [
  {
    id: 1,
    img: "img/service/1.png",
    title: "IT Remote Jobs",
    desc: "We provide remote job opportunities for IT professionals who are Web Developers,UX Designer....",
    fullDesc:
      "We provide remote job opportunities for IT professionals who are SharePoint Developers, Web Developers, App Developers, Full Stack Developers, and so much more. There are a lot of remote jobs for IT professionals on Talent Individuals; however, without our team effort to help you find the perfect job that matches your skills and preferences, it will be significantly harder to get one. Just as the world of tech is mostly remote today, our platform allows our talents to find and complete several IT remote jobs with multiple clients one by one or all at once. We also stay up to date with the best approaches to make remote jobs more manageable and achievable for our recruits.",
  },
  {
    id: 2,
    img: "img/service/2.png",
    title: " Satisfactory Customer Service",
    desc: "Our IT talents are given a full stock of options at Talent Individuals. ",
    fullDesc:
      "Our IT talents are given a full stock of options at Talent Individuals. Jobs are easier to secure, and clients are easier to communicate with. Because of our commitment to excellence, we have been equipped with the proper resources to provide high-quality services. We also have a team of customer care consultants who guide clients through recruitment. They carry out interviews and determine which talent best suits each job. We are dedicated to providing quality services to our clients and take pride in doing so effectively. Our team of professionals are dedicated to our vision at Talent Individuals, and they always do what it takes to assist our clients at each step.",
  },
  {
    id: 3,
    img: "img/service/3.png",
    title: "Reaching a Global Audience",
    desc: "We are a UK-based firm with our services readily available globally. Our services are not limited to one region as we assist companies worldwide....",
    fullDesc:
      "We are a UK-based firm with our services readily available globally. Our services are not limited to one region as we assist companies worldwide in achieving their small or large-scale goals and objectives. Our global outlook allows both clients and professionals to connect with the right jobs. In addition to this, we have the capacity to provide a variety of recruitment solutions, such as specialized searches, headhunting, outsourcing, and technical assistance, to help you reach your business goals by connecting clients with highly qualified candidates from all over the world who will precisely match their needs in terms of skills and experience. ",
  },
  {
    id: 4,
    img: "img/service/4.png",
    title: "Market Research",
    desc: "We at Talent Individuals understand market research's importance and use it to deliver excellent recruitment success.... ",
    fullDesc:
      "We at Talent Individuals understand market research's importance and use it to deliver excellent recruitment success. Our professionals use data from several sources to determine client and recruit needs. Talent Individual's thorough research approach allows our clients to stay at ease while we use the information we have gathered to provide top-quality recruits for our clients and high-value jobs for our recruits. We also use our research data to stay ahead of competitors and provide solutions to problems in the IT space. ",
  },
  {
    id: 5,
    img: "img/service/5.png",
    title: "Resources Planning",
    desc: "Talent Individuals caters to businesses or organizations with several tech related projects but insufficient staff....",
    fullDesc:
      "Talent Individuals caters to businesses or organizations with several tech related projects but insufficient staff. We assist our clients by taking over outsourcing responsibilities, allowing them to plan, invest, and save their organizations time and resources. We also provide a secure environment to ensure they get value for their money. ",
  },
  {
    id: 6,
    img: "img/service/6.png",
    title: "Secure Transactions",
    desc: "We at Talent Individuals take pride in our job success rate. Our team not only help recruit skilled IT professionals for clients, we also monitor job progress and milestones....",
    fullDesc:
      "We at Talent Individuals take pride in our job success rate. Our team not only helps recruit skilled IT professionals for clients, but we also monitor job progress and milestones. We have a secure payment system that guarantees little to no dispute when it's time for them to get paid. Some jobs are paid for in milestones; our team ensures clients pay accordingly in such cases. This also applies to jobs that require payments after projects are completed. We do not tolerate scams, unpaid wages, and abandoned jobs. Once such cases are reported, we immediately take action to ensure the safety of every person on our platform.",
  },
];
