export default [
  {
    id: 1,
    jobtype: "Digital & Design",
  },
  {
    id: 2,
    jobtype: "Information Security / Cyber Security",
  },
  {
    id: 3,
    jobtype: "Project & Programme Management",
  },
  {
    id: 4,
    jobtype: "Software Development & Engineering",
  },
  {
    id: 5,
    jobtype: "Technology Leadership",
  },
  {
    id: 6,
    jobtype: "Data & Analytics",
  },
];
