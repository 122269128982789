import React from "react";
import { useParams } from "react-router-dom";
import SubHeader from "../pageComponent/subHeader";
import jobData from "../sitedata/data";
import { Link } from "react-router-dom";
export default function ApplyJob() {
  const { job } = useParams();
  const data = jobData.find((data) => data.jobTitle === job);
  return (
    <>
      <SubHeader title={"Apply"} />
      <section className="top-career bg-light-white pt-60 pb-60">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-7 text-center text-lg-left">
              <p className="green f-500 mb-5">{data.contractDuration}</p>
              <h2 className="f-700 mb-5 lh-13">{job}</h2>
              <ul className="list-inline fs-12">
                <li className="list-inline-item pr-15">
                  <i className="fas fa-map-marker-alt mr-10 fs-14 green"></i>{" "}
                  {data.location}
                </li>
                <li className="list-inline-item pr-15">
                  <i className="fas fa-dollar-sign mr-10 fs-14 green"></i> $
                  {data.salary}
                  per {data.duration}
                </li>
              </ul>
            </div>
            <div className="col-lg-5 text-center text-lg-right">
              <p className="mb-5 fs-13">Posted 2 days ago</p>
              <Link
                to={`/sumbitcvforjob/${data.jobTitle}`}
                className="btn btn-square blob-small mb-10 mt-lg-20"
              >
                Apply Now<i className="fas fa-long-arrow-alt-right ml-20"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="career-detail pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="career-left">
                <h5 className="f-700 mb-10">Job Information</h5>
                <p>{data.jobDesc}</p>
                <h5 className="f-700 mb-10">Responsibilities Include:</h5>
                <ul className="check-list-2 mb-15">
                  {data.jobResponsibilities.map((eachRes) => (
                    <li>{eachRes}</li>
                  ))}
                </ul>
                <h5 className="f-700 mb-10">Background, Skills & Experience</h5>
                <ul className="check-list-2 mb-15">
                  {data.skillExperience.map((eachEx) => (
                    <li>{eachEx}</li>
                  ))}
                </ul>
                <div className="row align-items-center mt-35 mb-md-60">
                  <div className="col-md-6">
                    <Link
                      to={`/sumbitcvforjob/${data.jobTitle}`}
                      className="btn btn-square blob-small mb-10 mt-lg-20"
                    >
                      Apply Now
                      <i className="fas fa-long-arrow-alt-right ml-20"></i>
                    </Link>
                  </div>
                  <div className="col-md-6 mt-sm-30">
                    <div className="d-flex share-case justify-content-left justify-content-md-end">
                      <p className="fs-16 mr-20 mb-0">Share:</p>
                      <ul className="social-icons black ">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-google-plus-g"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="right-box bg-light-white mb-30">
                <div className="right-box-head">
                  <h4>Job Information</h4>
                </div>
                <div className="right-box-content">
                  <ul className="mt-15 mb-10">
                    <li>
                      <div className="other-dervices d-flex align-items-center">
                        <div className="icon">
                          <img src="img/career/gr1.png" alt="" />
                        </div>
                        <div className="icon-text">
                          <h6>Offered Salary</h6>
                          <p>${data.salary}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="other-dervices d-flex align-items-center">
                        <div className="icon">
                          <img src="img/career/gr2.png" alt="" />
                        </div>
                        <div className="icon-text">
                          <h6>Experience</h6>
                          <p>{data.experience} year +</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="other-dervices d-flex align-items-center">
                        <div className="icon">
                          <img src="img/career/gr3.png" alt="" />
                        </div>
                        <div className="icon-text">
                          <h6>Contract Type</h6>
                          <p>{data.contractDuration} </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="right-box bg-light-white mb-30">
                <div className="right-box-head">
                  <h4>Need Help?</h4>
                </div>
                <div className="right-box-content">
                  <ul className="mt-10 mb-10">
                    <li>
                      <div className="d-flex">
                        <div className="icon-round-box">
                          <div className="icon-round-box-icon">
                            <i className="fas fa-phone-volume green"></i>
                          </div>
                        </div>
                        <div className="icon-round-box-text">
                          <p>
                            <span>Phone:</span> +44 207 642 2814
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div className="icon-round-box">
                          <div className="icon-round-box-icon">
                            <i className="far fa-envelope green"></i>
                          </div>
                        </div>
                        <div className="icon-round-box-text">
                          <p>info@talentindividuals.co.uk</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div className="icon-round-box">
                          <div className="icon-round-box-icon">
                            <i className="fas fa-map-marker-alt green"></i>
                          </div>
                        </div>
                        <div className="icon-round-box-text">
                          <p>
                            Unit F39 Water Front Studio 1 Dock Road, Canning
                            Town, London, E16 1AH
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
