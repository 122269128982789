import React from "react";
import Alljobapplications from "./alljobapplications";
import { Link } from "react-router-dom";
import  Editcredential  from "./editcredential";
import ClientInfo from "./clientInfo";
import Savedjobs from "./savedjobs";
import UploadCv from "./uploadCv";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/api/apiSlice";

export default function Dashboard() {

  const {data: user } = useSelector(selectUserData)

  const  dashboard = user ? (
    <section className="container-fluid dashboard pt-100 pb-100">
      <h1 className="f-700 text-white text-center mt-10 mb-50 pt-50">
        TALENTS DASHBOARD
      </h1>
      <div className="container bg-light-white mb-30 img-lined shadow-1">
        <div className="client-name-details col-md-12 text-center pt-50 pb-50">
          <h3 className="client-name f-700 mb-20 text-capitalize">
            {user.firstname} {user.lastname}
          </h3>
          <h5 className="client-name f-700 mb-20 text-capitalize">
            {user.jobType}
          </h5>
          <h5 className="client-mail f-700 mb-30"> {user.email}</h5>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-around">
          {/* THIS SECTION HANDLES CLIENT NAME, ROLES, EMAIL */}
          <ClientInfo />

          {/* THIS SECTION HANDLES CLIENT SAVED JOBS */}
          <Savedjobs />

          {/* THIS SECTION HANDLES CLIENT APPLIED JOB */}
          <Alljobapplications />

          {/* THIS SECTION HANDLES CLIENT CV UPLOAD*/}
          <UploadCv user={user}/>

          {/* THIS SECTION HANDLES CLIENT EDIT AND LOGOUT */}
          <Editcredential  user={user} />
        </div>
      </div>
    </section>
  ) : (
    ""
  );

  return dashboard;
}
