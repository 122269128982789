import React from "react";

export default function UploadCv() {
  let CV_NAME = "dynamite.docx";
  return (
    <div className="upload-cv col-md-5 p-5 mb-30 text-left bg-light-white img-lined shadow-1">
      <h3 className="f-700 fs-19 mb-20">Candidate files</h3>
      <div className="cv-name mb-10">
        <h6>{CV_NAME}</h6>
      </div>

      <p className="m-0">Upload a new Resume</p>
      <div className="file-stats">
        <div className="uploadcv">
          <label htmlFor="">Select File</label>
          <input type="file" className="uploadcv__input" />
          <div className="uploadcv__icon">
            <i class="fas fa-upload"></i>
          </div>
        </div>
      </div>
    </div>
  );
}
