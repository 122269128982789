import React from "react";
import SubHeader from "../pageComponent/subHeader";

export default function Faq() {
  return (
    <>
      <SubHeader title={"Frequently Asked Questions"} />
      <section className="service-details pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sercice-content">
                <h1 className="f-700 mt-30 mb-10 text-center">FAQ</h1>
                <p>
                  Morbi elementum consequat ornare. Morbi ac rutrum mauris.
                  Praesent elit ex, sagittis at turpis sed, suscipit accumsan
                  mi. Etiam eget nulla suscipit, tempus nibh in, bibendum arcu.
                  Nullam rutrum nibh purus, sit amet elementum eros fermentum
                  at.
                </p>
                <div className="faq-box bodered-faq mt-30 mb-40">
                  <div id="accordion">
                    <div className="card">
                      <div
                        className="card-header"
                        id="headingOne"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        role="button"
                      >
                        <h5 className="mb-0">
                          Lorem ipsum dolor sit amet, consectetur?
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header  collapsed"
                        id="headingTwo"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        role="button"
                      >
                        <h5 className="mb-0">
                          Aenean lobortis feugiat mauris at?
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header collapsed"
                        id="headingThree"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        role="button"
                      >
                        <h5 className="mb-0">
                          Nulla porttitor risus id mauris viverra?
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
