import React from "react";
import experienceCtaData from "../sitedata/experienceCtaData";

export default function ExperienceCta() {
  const ExperienceCtaList = experienceCtaData.map((each) => (
    <div className="col-sm-6 text-center">
      <div className="each-count-up shade z-5">
        <h2 className="fs-40 f-900 green mb-20 mt-5">
          <span className="counter">{each.count}</span>+
        </h2>
        <p className="uppercase white mb-0">{each.title}</p>
      </div>
    </div>
  ));
  return (
    <section
      className="experience-cta pt-100 pb-100"
      style={{ backgroundImage: "url('img/banner/inner-banner.jpg')" }}
      data-overlay="9"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 z-5 text-center text-lg-left">
            <div className="exp-cta pr-50 pr-lg-00">
              <h1 className="white f-700 mb-10">
                <span className="green">2 Years</span> of Experience in the
                <span className="green">Industry</span>
              </h1>
              <p className="white mb-55 mb-md-30 pr-60 pr-md-00">
                Text Content coming soon
              </p>
              <a href="#!" className="btn btn-square ">
                Our Services
                <i className="fas fa-long-arrow-alt-right ml-20"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-6 mt-md-60">
            <div className="row no-gutters">{ExperienceCtaList}</div>
          </div>
        </div>
      </div>
    </section>
  );
}
