import React from "react";
import jobtypeData from "../../sitedata/jobtypeData";
import jobContracts from "../../sitedata/jobContracts";
import { FormButton } from "../../components/formbtn";
import { SelectInput } from "../../components/formcomponents";



export default function JobTypes({setStep, step, handleSubmit, jobType,setJobType, jobContract,setJobContract}) {



  let jobTypes = jobtypeData.map((eachJob) => (
    <option value={eachJob.jobtype} key={eachJob.id}>
      {eachJob.jobtype}
    </option>
  ));

  let contractType = jobContracts.map((eachContract) => (
    <option value={eachContract.contract} key={eachContract.id}>
      {eachContract.contract}
    </option>
  ));
  return (
    <div>
      <section className="login pt-100 pb-100">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xl-7 col-lg-6 d-none d-lg-block">
              <div
                className="login-image bg-cover h-100"
                style={{ backgroundImage: "url('img/bg/login.jpg')" }}
              ></div>
            </div>
            <div className="col-xl-5 col-lg-6 ">
              <div className="form-area bg-light-white">
                <h2 className="f-700 mb-15">SET UP A JOB</h2>
                <p>
                  You will receive alerts to your inbox when similar jobs become
                  available.
                </p>
                <div>
                  <SelectInput type={jobTypes} name="jobType" selected="JOB TYPE" change={setJobType} />
                  <SelectInput type={contractType} name="JobContract" selected="Job Contract"  change={setJobContract} />
                  <FormButton handleSubmit={handleSubmit} setStep={setStep} step={step}  className="btn btn-square btn-block shadow-4 mt-20" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
