
import { useContext } from "react"
import { LoaderContext } from "./loadercontext"

export const FormButton = ({className, setStep, step,  handleSubmit , Validate}) => {


  const { loading } = useContext(LoaderContext)
   
    const handleClick = () => {
      if(!Validate) return
        setStep(step => step + 1)
      }

      const Button = step === 4 ? (
        <button  className={className} onClick={handleSubmit}>Submit</button>
      ) : (
        <button  className={className} onClick={handleClick}>Next</button>
      )
      
      if(loading){
        return (
          <div className="loader_container">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>)
      }

      return Button
}
