import React from "react";

export default function ClientInfo() {
  return (
    <div className="client-activities col-md-5 p-5 mb-30 text-left bg-light-white img-lined shadow-1">
      <h3 className="f-700 fs-19 mb-10">Your Activity</h3>

      <div className="activities-stats">
        <h5 className="mb-2">
          Application: <span>0</span>
        </h5>
        <h5 className="mb-2">
          Active job alerts: <span>0</span>
        </h5>
        <h5 className="mb-2">
          CVs: <span>0</span>
        </h5>
      </div>
    </div>
  );
}
