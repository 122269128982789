import React from "react";
import { useParams } from "react-router";
import servicesData from "../sitedata/servicesData";

export default function IndividualServiceLoad() {
  const { service } = useParams();

  const data = servicesData.find((data) => data.title === service);

  console.log(data);

  return (
    <>
      <section
        className="inner-banner pt-80 pb-95"
        style={{ backgroundImage: "url('img/banner/inner-banner.jpg')" }}
        data-overlay="7"
      >
        <div className="container">
          <div className="row z-5 align-items-center">
            <div className="col-md-8 text-center text-md-left">
              <h1 className="f-700 green">{service}</h1>
              <span className="green-line bg-green d-none d-md-inline-block"></span>
            </div>
            <div className="col-md-4 text-center text-md-right">
              <nav aria-label="breadcrumb" className="banner-breadcump">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="#">
                      <i className="fas fa-home fs-12 mr-5"></i>Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {service}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="service-details pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="left-part">
                <div className="service-img">
                  <img src="img/service/service-detail.jpg" alt="" />
                </div>
                <div className="sercice-content">
                  <h1 className="f-700 mt-30 mb-10">{service}</h1>

                  <p>{data.desc}</p>
                  <div className="block-quote bg-light-white mb-20">
                    <p className="blue fs-18 lh-19 mb-0">{data.fullDesc}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="right-box ad-banner bg-light-white mb-30">
                <a href="#" className="d-block ">
                  <img
                    src="img/service/ad-banner.jpg"
                    className="w-100"
                    alt=""
                  />
                </a>
              </div>

              <div className="right-box bg-light-white mb-30">
                <div className="right-box-head">
                  <h4>Follow Us</h4>
                </div>
                <div className="right-box-content">
                  <div className="social-profile mt-10 mb-10">
                    <a href="#" className="social-profile-box">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      href="https://twitter.com/TalentIndividu"
                      className="social-profile-box"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#" className="social-profile-box">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
