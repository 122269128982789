export default [
  {
    id: 1,
    title: "Our Work Approach",
    desc: "Our strategy enables us to locate the best employees for your company's needs and culture.",
  },
  {
    id: 2,
    title: "Collaborative work ",
    desc: "Working with us provides a partner who handles all aspects of the recruitment process.",
  },
  {
    id: 3,
    title: "Guaranteed Jobs",
    desc: "TalentIndividuals.co.uk will assist you in finding your ideal job! We have experienced staff who can guide IT professionals and help them choose the best career route according to their abilities and knowledge. We also profile and place recruits in appropriate positions.",
  },
  {
    id: 4,
    title: "Trusted By Thousands of Brands and Companies.",
    desc: "Thousands of brands and businesses use our services. They rely on us to locate the perfect talents for their needs because of our reputation as a comprehensive recruitment service provider with high-quality standards.",
  },
];
