import React , {useState} from "react";
import { useParams } from "react-router-dom";
import { Input } from "../components/formcomponents";
import { useUploadCvMutation } from "../redux/api/apiSlice";

export default function SumbitCvForJob() {

  const [file, setCv] = useState()

  const [UploadCv, { isLoading, error }] = useUploadCvMutation()


  const handleSubmit = async (e) => {
    if(!error){
      alert("CV sumbitted, Go Back");
    }
    let form_data = new FormData()
    form_data.append("file",file )

   try {
    const result  = await UploadCv(form_data).unwrap()
    console.log(result)
   }catch(err){
    console.log(err)
   }
    
  };

  const { title } = useParams();
  return (
    <section
      className="bg-light-white pt-100 pb-100"
      style={{ height: "100vh" }}
    >
      <div className="container text-center">
        <h1>SEND YOUR CV</h1>
        <h5 className="pt-20 pb-20">You Are Applying For a {title} role.</h5>

        <div className="uploadcv">
          <label htmlFor="">Select File</label>
          <Input
            type="file"
            className="uploadcv__input"
            change={setCv}
            name="cv"
          />
          <div className="uploadcv__icon">
            <i className="fas fa-upload"></i>
          </div>
        </div>

        <div className="col-lg-12 text-center mt-30 pt-40">
          {isLoading ? (
            <div className="loader_container">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
          ) : (
            <button className="btn btn-square  blob-small" onClick={handleSubmit}>
             Summit
            <i className="fas fa-long-arrow-alt-right ml-20"></i>
          </button>
          )}
        </div>
      </div>
    </section>
  );
}
