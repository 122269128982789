import React from "react";
import { Link } from "react-router-dom";
export default function Header({ auth }) {
  const [signUp, SetSignUp] = React.useState(false);

  // Mobile Menu start
  const [openMobileMeu, setOpenMobileMeu] = React.useState(false);

  const handleClick = () => {
    setOpenMobileMeu((prevState) => !prevState);
  };

  // Mobile Menu end
  return (
    <header className="transperant-head transition-4 ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-2 col-md-5 col-sm-4 col-5">
            <div className="logo transition-4">
              <Link to="/">
                <img src="./img/logo/logo-2.png" alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-10 col-md-7 col-sm-8 col-7">
            <div className="icon-links d-flex align-items-center">
              <Link
                to="/contact"
                className="btn btn-round d-none d-sm-block blob-small"
              >
                CONTACT US
              </Link>
              <div className="hamburger text-white">
                <i
                  className={openMobileMeu ? "fas fa-times" : "fas fa-bars"}
                  onClick={handleClick}
                ></i>
              </div>
            </div>
            <div className={openMobileMeu ? "menu-links show" : "menu-links"}>
              <nav className="main-menu white">
                <ul>
                  <li className="active">
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <a href="#">
                      Careers <i className="fas fa-angle-down"></i>
                    </a>
                    <ul className="submenu">
                      <li>
                        <Link to="/career">Jobs</Link>
                      </li>
                      <li>
                        <Link to="/workwithus">Work For Us</Link>
                      </li>
                      <li>
                        <Link to="/contact">Hire Talents</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#!">
                      About Us <i className="fas fa-angle-down"></i>
                    </a>
                    <ul className="submenu">
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/team">Our Team</Link>
                      </li>
                      <li>
                        <Link to="/partners">Partners</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    {!auth ? (
                      <Link to={signUp ? "/login" : "/signup"}>
                        Sign Up / Login
                      </Link>
                    ) : (
                      <Link to="/dashboard">Account</Link>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-12">
            <div className="mobile-menu"></div>
          </div>
        </div>
      </div>
    </header>
  );
}
