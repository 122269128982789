import React from "react";

export default function Search() {
  return (
    <div
      className="search-popup modal fade"
      id="searchModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="searchModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <form action="#">
              <div className="form-group relative">
                <input
                  type="text"
                  className="form-control input-search"
                  id="search"
                  placeholder="Search here..."
                />
                <i className="fas fa-search transform-v-center"></i>
              </div>
            </form>
          </div>
        </div>
      </div>
      <i
        className="fas fa-times close-search-modal"
        data-dismiss="modal"
        aria-label="Close"
      ></i>
    </div>
  );
}
