

export const Input = ({placeholder, icon, name, type, className, value, change}) => {

    return(
        <div className={className}>
            {type === "file" ? (
                <input type={type} name={name} placeholder={placeholder} className="form-control input-lg input-white shadow-5"  onChange={e => change(e.target.files[0])} required />
            ) : (
                <input type={type} name={name} placeholder={placeholder} className="form-control input-lg input-white shadow-5" value={value} onChange={e => change(e.target.value)} required />
            )}
            <i className={icon}></i>
         </div>
    )

}


export const RadioBox = ({ value, change }) => {

    return(
        <div className="talent d-flex my-2 mx-4">
                <input type="radio" name="signup_choices" className="label__input mx-2" value={value} onChange={e => change(e.target.value)} required />
                <h4>{value}</h4>
        </div>
    )

}

export const SelectInput = ({selected, type, name, change}) => {
    return (
        <div className="form-group relative mb-25 mb-sm-20">
            <select
                    class="form-select form-control input-lg input-white shadow-5"
                    aria-label="Default select"
                    name={name}
                    onChange={e => change(e.target.value)}
                    required
                >
                    <option selected>- {selected} -</option>
                    {type}
                </select>
        </div>
    )
}
