import React from "react";
import { Link } from "react-router-dom";
import { Input } from "../../components/formcomponents";
import { FormButton } from "../../components/formbtn";

export default function Signup({
  setStep,
  username,
  setUsername,
  password,
  setPassword,
  email,
  setEmail,
}) {
  const Validate = username !== "" && password !== "" && email !== "";

  return (
    <div>
      <section
        className="login pt-100 pb-100"
        style={{ background: "#001c6b" }}
      >
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xl-7 col-lg-6 d-none d-lg-block">
              <div
                className="login-image bg-cover h-100"
                style={{ backgroundImage: "url('img/bg/login.jpg')" }}
              ></div>
            </div>
            <div className="col-xl-5 col-lg-6 ">
              <div className="form-area bg-light-white">
                <h2 className="f-700 mb-15">Create an Account</h2>
                <p>
                  Please enter the following information to create your account.
                </p>
                <Input
                  type="text"
                  id="name"
                  name="username"
                  placeholder="Username"
                  icon="far fa-user transform-v-center"
                  className="form-group relative mb-25 mb-sm-20"
                  value={username}
                  change={setUsername}
                />
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  className="form-group relative mb-25 mb-sm-20"
                  value={email}
                  change={setEmail}
                />
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  className="form-group relative mb-25 mb-sm-20"
                  value={password}
                  change={setPassword}
                />
                <div className="form-group form-check pl-0">
                  <div className="d-flex justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        checked=""
                      />
                      <label
                        className="custom-control-label fs-13"
                        for="customCheck1"
                      >
                        <span className="label-check">Remember me</span>
                      </label>
                    </div>
                  </div>
                </div>
                <FormButton
                  className="btn btn-square btn-block shadow-4 mt-20"
                  setStep={setStep}
                  Validate={Validate}
                />
                <div className="signup-login text-center">
                  <p className="mt-15 fs-13">
                    Already a member?
                    <Link
                      to="/login"
                      className="ml-5 mb-0 d-inline-block f-500"
                    >
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
