import React from "react";
import ExperienceCta from "../pageComponent/experienceCta";
import LetTalk from "../pageComponent/letTalk";
import Search from "../pageComponent/search";
import SubHeader from "../pageComponent/subHeader";
import TeamArea from "../pageComponent/teamArea";
export default function About() {
  return (
    <div className="aboutus">
      {/*******************
       Search START
      ********************/}
      <Search />
      {/*******************
       Search END
      ********************/}

      {/*******************
       SUBHEADER START
      ********************/}
      <SubHeader title={"About Us"} />
      {/*******************
 SUBHEADER END
 ********************/}
      {/********* 
      ABOUT US START
      ***********/}
      <section className="about-us pt-100 pb-100">
        <div className="container">
          <div className="row align-items-xl-center">
            <div className="col-lg-6">
              <div className="relative img-lined bg-blue mx-auto shadow-1">
                <img
                  src="img/about/about_video.jpg"
                  alt=""
                  className="opacity-3 w-100"
                />
                <div className="blob green transform-center">
                  <a href="#!" className="popup-video">
                    <i className="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-text mt-md-60 text-center text-lg-left">
                <div className="fancy-head left-al  mb-10">
                  <h5 className="line-head mb-10">
                    <span className="line before d-lg-none"></span>
                    About Us
                    <span className="line  after"></span>
                  </h5>
                  <h1>Know Us</h1>
                </div>
                <p className="big-p blue">
                  Talent Individuals is a platform run by professionals who can
                  deliver high-quality recruitment outcomes. We take great pride
                  in our Market Research system, which enables us to achieve the
                  best outcomes for our valuable clients.
                </p>
                <div className="about-author mt-20 d-flex align-items-center justify-content-center justify-content-lg-start">
                  <div className="name-author">
                    <p className="name">Yusuf Akinoso</p>
                    <p>Founder & CEO</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/********* 
      ABOUT US START
      ***********/}

      <section className="mission-vision bg-light-white pt-100 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pr-50 pr-sm-150 mb-md-30">
              <h2 className="fancy-2 f-700 mb-25"> Our Mission</h2>
              <p className="f-500 blue fs-15">
                Our focus is to assist businesses in meeting the right IT
                professionals and extraordinary talents for their Organisations
                while also serving job-seekers needs with our strategic means.
              </p>
            </div>
            <div className="col-lg-6 pr-50 pr-sm-15">
              <h2 className="fancy-2 f-700 mb-25"> Our Vision</h2>
              <p className="f-500 blue fs-15">
                We are devoted to remaining unmatched in customer satisfaction.
                We strive to maintain our reputation as:
              </p>
              <ul className="check-list-2">
                <li>
                  A friendly organization that supports employees and employers
                </li>
                <li>
                  A platform that upholds high principles and integrity amongst
                  professionals{" "}
                </li>
                <li>
                  An enthusiastic and trusted partner that dedicates its time
                  and resources to ensure our talents have good wages and
                  benefits.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/********* 
      ExperienceCta START
      ***********/}
      <ExperienceCta />
      {/********* 
      ExperienceCta START
      ***********/}

      {/********* 
      TeamArea Start
      ***********/}
      <TeamArea />
      {/********* 
      TeamArea end
      ***********/}

      {/********* 
      TeamArea Start
      ***********/}
      <LetTalk />
      {/********* 
      TeamArea end
      ***********/}
    </div>
  );
}
