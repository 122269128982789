import React from "react";

export default function Alljobapplications() {
  return (
    <div className="all-applications col-md-12 mb-30 p-5 text-left bg-light-white img-lined shadow-1">
      <h3 className="f-700 fs-19 mb-10">Applications</h3>
      <div className="applications-stats">
        <p>You Have not applied for any job yet</p>
      </div>
    </div>
  );
}
