import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useResetPasswordMutation } from "../redux/api/apiSlice";

export default function EditPasswordModal({passwordModal, setPasswordModal}) {

  const [ oldpassword, setOldPassword ] = useState("")
  const [ newpassword, setNewPassword ] = useState("")

  const [resetPassword, {isLoading}] = useResetPasswordMutation()

  const handleSubmit = async e => {
    try{
      const result = await resetPassword(({oldpassword, newpassword})).unwrap()
      console.log(result)
    }catch(err){
      console.log(err)
    }
  }
  

  return (
    <section className="edit-credential pt-100 pb-100" data-overlay="7">
      <div className="container">
        <div className="exit-modal" onClick={() => {setPasswordModal(!passwordModal)}}>
          <i className="fa fa-times transform-v-center"></i>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="relative z-5 mt-10">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group relative mb-30 mb-sm-20">
                    <input
                      type="password"
                      className="form-control input-lg input-white shadow-5"
                      id="name"
                      placeholder="Old Password"
                      value={oldpassword}
                      onChange={e => setOldPassword(e.target.value)}
                    />
                    <i className="far fa-user transform-v-center "></i>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group relative mb-30 mb-sm-20">
                    <input
                      type="password"
                      className="form-control input-lg input-white shadow-5"
                      id="name"
                      placeholder="New Password"
                      value={newpassword}
                      onChange={e => setNewPassword(e.target.value)}
                    />
                    <i className="far fa-user transform-v-center "></i>
                  </div>
                </div>

                <p><Link to="">Forgot Password</Link></p>
                <div className="col-lg-12 text-center mt-30">
                  <button href="#" className="btn btn-square  blob-small" onClick={handleSubmit}>
                  {isLoading ? "Loading..." : "Done"}<i className="fas fa-long-arrow-alt-right ml-20"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
