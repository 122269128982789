import React from "react";

export default function TeamArea() {
  return (
    <section className="team-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-30">
            <div
              className="team-green-head h-100 d-flex align-items-center pl-50 pr-50 pl-lg-30 pr-lg-30"
              style={{ backgroundImage: "url('img/bg/bg_hands.jpg')" }}
              data-overlay="9"
            >
              <div className="fancy-head left-al z-5 pt-md-30 pb-md-30">
                <h5 className="line-head mb-10 white">
                  About Us
                  <span className="line after bg-white"></span>
                </h5>
                <h1 className="white mb-5">Teams of TalentIndividuals</h1>
                <p className="white mb-25">
                  We have been devoted to remaining unmatched in customer
                  satisfaction in the industry for the past two years.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-30">
            <div className="team-2-each relative bg-black shadow-3">
              <a href="#">
                <img src="img/team/team2.jpg" alt="" />
              </a>
              <div className="team-hover-div transition-4">
                <h5 className="white f-700">
                  <a href="#">Yusuf Akinoso</a>
                </h5>
                <p className="green mb-0">CEO/Founder</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-30 text-center">
            <div
              className="career-box relative d-flex img-lined-after  shadow-3  align-items-center justify-content-center h-100"
              style={{ backgroundImage: "url('img/team/career.jpg')" }}
              data-overlay="9"
            >
              <div className="career-text z-5">
                <h5 className="f-700 white mb-20">Want to Join Our Team?</h5>
                <a href="#" className="btn btn-round-blue">
                  CAREER
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
