

export const FormBtn2 = ({setStep, cv,signup_choices, Validate, }) => {


    const handleClick = () => {
        console.log(cv)
        if(!Validate) return
        if(signup_choices === "recruiter"){
            setStep(step => step + 2)
            return
        }
        if(signup_choices === "talent" && cv === "") {
            setStep(step => step + 1)
        }else if(signup_choices === "talent") {
            setStep(step => step + 1)
        }else{
            setStep(step => step + 1)
        }
    }
    return (
        <div className="col-lg-12 text-center mt-30">
            <button  className="btn btn-square  blob-small" onClick={handleClick}>
                Next
                   <i className="fas fa-long-arrow-alt-right ml-20"></i>
            </button>
        </div>
    )
}
