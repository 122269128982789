import React from "react";
import featureAreaData from "../sitedata/featureAreaData";

export default function FeaturesArea() {
  let FeaturesAreaList = featureAreaData.map((each) => (
    <div className="col-sm-4 text-center text-lg-left" key={each.id}>
      <div className="icon-box d-flex flex-column flex-lg-row align-items-center">
        <div className="icon-img mr-20 mr-md-00 mb-md-10">
          <img src={each.icon} alt="Icon" />
        </div>
        <div className="icon-text">
          <h5 className="f-700">{each.title}</h5>
          <p>{each.desc}</p>
        </div>
      </div>
    </div>
  ));
  return (
    <section className="features-area bg-light-white pt-40 pb-40 pb-xs-00">
      <div className="container">
        <div className="row">{FeaturesAreaList}</div>
      </div>
    </section>
  );
}
