import React from "react";
import ApplicationForm from "../pageComponent/applicationForm";
import ClientList from "../pageComponent/clientList";
import SubHeader from "../pageComponent/subHeader";

export default function Workwithus() {
  return (
    <div>
      <SubHeader title={"Work With Us"} />

      <section
        className="contact-form  bg-light-white pt-100 pb-100"
        style={{ backgroundImage: "url('img/banner/inner-banner.jpg')" }}
        data-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="fancy-head text-center relative z-5 mb-40">
                <h5 className="line-head mb-15 ">
                  <span className="line before "></span>
                  submit job
                  <span className="line after"></span>
                </h5>
                <h1 className="mb-5">Application Form</h1>
                <p className="small-p">
                  Please Complete The Form Below To Submit A Job
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="relative z-5 mt-10">
                <ApplicationForm />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CLIENT LIST START */}
      <ClientList />
      {/* CLIENT LIST END */}
    </div>
  );
}
