import Signup from "./signup"
import JobTypes from "./jobType"
import Talentcvupload from "./talentcvupload"
import ClientDetail from "./clientdetails"
import { useState, useContext } from "react"
import { useRegisterUserMutation } from "../../redux/api/apiSlice"
import  { useNavigate}  from "react-router-dom"
import { LoaderContext } from "../../components/loadercontext"
 

function SIgnUpForm(){

    let navigate = useNavigate()

    const { loading,setLoading } = useContext(LoaderContext)


    const [step, setStep] = useState(1)
    const [ username, setUsername ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ firstname, setFirstname ] = useState('')
    const [ lastname, setLastname] = useState('')
    const [ signup_choices, setSignUpChoices] = useState()
    const [ cv, setCv ] = useState('')
    const [ jobType, setJobType ] = useState('')
    const [ jobContract, setJobContract ] = useState('')
   
    const [registerUser, { error } ] = useRegisterUserMutation()

    const handleSubmit = async () => {
        setLoading(!loading)
        if(username === "" &&  email === "" && password === "" && firstname === "" && lastname === ""  && signup_choices === ""  &&  jobType === "" && jobContract === "") return
        try{
            let formdata = new FormData()
            formdata.append("username", username)
            formdata.append("firstname", firstname)
            formdata.append("lastname", lastname)
            formdata.append("email", email)
            formdata.append("password", password)
            formdata.append("signup_choices", signup_choices)
            formdata.append("cv", cv)
            formdata.append("jobType", jobType)
            formdata.append("jobContract", jobContract)
            const result = await registerUser(formdata).unwrap()
            localStorage.setItem("token", result.token)
            if(result) setLoading(loading)
            navigate("/dashboard")
            setUsername("")
            setEmail("")
            setPassword("")
            setFirstname("")
            setLastname("")
            setSignUpChoices("")
            setCv("")
            setJobType("")
            setJobContract("")

            navigate("/dashboard")
        }catch(err){
            console.log(err)
            setLoading(loading)
        }
      
    }


    switch(step){
        case 1: 
            return <Signup 
            setUsername={setUsername} 
            username={username}
            password={password}
            setPassword={setPassword}
            setStep={setStep} 
            email={email}
            setEmail={setEmail}  />
        case 2:
            return <ClientDetail 
            firstname={firstname}
            setFirstname={setFirstname}
            lastname={lastname}
            setLastname={setLastname}
            setStep={setStep} 
            signup_choices={signup_choices}
            setSignUpChoices={setSignUpChoices}
            cv={cv}
                />
        case 3: 
            return <Talentcvupload
                    setCv={setCv}
                    cv={cv}
                    setStep={setStep}
                />
        case 4: 
            return <JobTypes 
                jobType={jobType} 
                setJobType={setJobType}
                jobContract={jobContract}
                setJobContract={setJobContract}
                setStep={setStep}
                step={step} 
                handleSubmit={handleSubmit} /> 
        default:
            return
    }


}

export default SIgnUpForm