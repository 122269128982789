import React from "react";

export default function Partners() {
  return (
    <div>
      <section
        className="inner-banner pt-80 pb-95"
        style={{ backgroundImage: "url('img/banner/inner-banner.jpg')" }}
        data-overlay="7"
      >
        <div className="container">
          <div className="row z-5 align-items-center">
            <div className="col-md-8 text-center text-md-left">
              <h1 className="f-700 green">Partners</h1>
              <span className="green-line bg-green d-none d-md-inline-block"></span>
            </div>
            <div className="col-md-4 text-center text-md-right">
              <nav aria-label="breadcrumb" className="banner-breadcump">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="#">
                      <i className="fas fa-home fs-12 mr-5"></i>Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Partners
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="partners pt-90 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="fancy-head text-center relative z-5 mb-45 wow fadeInDown"
                style={{ visibility: "visible", animationName: "fadeInDown" }}
              >
                <h1 className="">
                  <span className="green counter">350</span>+ Happy Clients &
                  Talents
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 m-5">
              <h1 className="text-center">Brand Coming Soon</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="hr-2 bg-black opacity-1 mt-0 mb-60"></div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="cta pt-50 pb-50"
        style={{ backgroundImage: "url('img/bg/bg_cta.jpg')" }}
        data-overlay="9"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-5 mb-md-20 text-center text-lg-left">
              <h3 className="z-5 white f-700 lh-18 wow fadeInLeft">
                You Need Talents or You Need To Land Your Dream Job?
                <span className="green italic">Let’s talk</span>
              </h3>
            </div>
            <div className="col-xl-8 col-lg-7 text-center text-lg-right z-5">
              <a
                href="#!"
                className="btn btn-square-white mr-20 mr-xs-00 d-block d-sm-inline-block mb-xs-15 wow fadeInUp"
              >
                <i className="fas fa-envelope mr-15"></i>
                info@talentindividuals.co.uk
              </a>
              <a
                href="tel:1234567890"
                className="btn btn-square-green d-block d-sm-inline-block blob-small wow fadeInUp"
              >
                <i className="fas fa-phone mr-15"></i>+1 234 567 890
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
