import React, { useState } from "react";
import { useUpdateUserMutation } from "../redux/api/apiSlice";

export default function EditCredentialModal({ detailModal, setDetailModal, user }) {

  const [ email, setEmail ] = useState(user.email)
  const [ firstname, setFirstname ] = useState(user.firstname)
  const [ lastname, setLastname] = useState(user.lastname)
  const [ cv, setCv ] = useState(user.cv)

  const [updateUser, {isLoading}] = useUpdateUserMutation()


  const handleSubmit = async e => {
    try{
      const result = await updateUser(JSON.stringify({firstname, lastname, email})).unwrap()
      console.log(result)
    }catch(err){
      console.log(err)
    }
  }
  

  return (
    <section className="edit-credential pt-100 pb-100" data-overlay="7">
      <div className="container">
        <div className="exit-modal" onClick={() => setDetailModal(!detailModal)}>
          <i className="fa fa-times transform-v-center"></i>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="relative z-5 mt-10">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group relative mb-30 mb-sm-20">
                    <input
                      type="text"
                      className="form-control input-lg input-white shadow-5"
                      id="name"
                      placeholder="Edit First Name"
                      value={firstname}
                      onChange={e => setFirstname(e.target.value)}
                    />
                    <i className="far fa-user transform-v-center "></i>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group relative mb-30 mb-sm-20">
                    <input
                      type="text"
                      className="form-control input-lg input-white shadow-5"
                      id="name"
                      placeholder="Edit Last Name"
                      value={lastname}
                      onChange={e => setLastname(e.target.value)}
                    />
                    <i className="far fa-user transform-v-center "></i>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group relative mb-30 mb-sm-20">
                    <input
                      type="email"
                      className="form-control input-lg input-white shadow-5"
                      id="current-address"
                      placeholder="Edit Mail"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <i className="far fa-envelope transform-v-center"></i>
                  </div>
                </div>
                <div className="col-md-12 justify-content-center">
                  <div className="form-group relative">
                    <label for="cv" style={{ fontWeight: "bold" }}>
                      Upload Resume *
                    </label>
                    <input
                      type="file"
                      id="cv"
                      className="form-control input-white shadow-2"
                      onChange={e => setCv(e.target.files[0])} 
                    />
                  </div>
                </div>
                <div className="col-lg-12 text-center mt-30">
                  <button href="#" className="btn btn-square  blob-small" onClick={handleSubmit}>
                  {isLoading ? "Loading..." : "Done"}<i className="fas fa-long-arrow-alt-right ml-20"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
