import React from "react";
import { FormBtn2 } from "../../components/formbtn2";
import { Input, RadioBox } from "../../components/formcomponents";

export default function ClientDetail({step, setStep ,firstname,setFirstname,lastname,setLastname, setSignUpChoices, signup_choices}) {

  const Validate = firstname !== ""  && lastname !== "" && signup_choices !== ""

  return (
    <section className="login pt-40 pb-40">
      <div className="container center">
        <section
          className="contact-form  bg-light-white pt-70 pb-70"
          data-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="fancy-head text-center relative z-5 mb-40">
                  <h1 className="mb-5">YOUR PERSONAL DETAILS</h1>
                  <p className="small-p">
                    Please give us a few more details, which will help us find
                    the right role for you...
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div  className="relative z-5 mt-10">
                  <div className="row">
                    <div className="col-md-6">
                        <Input   type="text" className="form-group relative mb-30 mb-sm-20" id="name" name="firstName"  placeholder="FirstName" icon="far fa-user transform-v-center" change={setFirstname} />
                      </div>
                    <div className="col-md-6">
                    <Input   type="text" className="form-group relative mb-30 mb-sm-20" id="name" name="lastName"  placeholder="lastName" icon="far fa-user transform-v-center" change={setLastname} />
                    </div>
                    <div className="col-md-12 mt-5">
                      <div className="client-choice">
                        <h5 className="text-center mb-5">
                          Are You Sign Up As a
                        </h5>
                        <div className="row justify-content-center">
                          <div className="talent d-flex my-2 mx-4">
                              <RadioBox  value="talent" change={setSignUpChoices}  />
                          </div>
                          <div className="recruiter d-flex my-2 mx-4">
                              <RadioBox value="recruiter" change={setSignUpChoices} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormBtn2  signup_choices={signup_choices} setStep={setStep} Validate={Validate} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
