import React from "react";
import Header from "../Navigations/header";
import Footer from "../Navigations/footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Testimonials from "../pageComponent/testimonials";
import Services from "../pageComponent/services";
import Search from "../pageComponent/search";
import FeaturesArea from "../pageComponent/featuresArea";
import FeatureListArea from "../pageComponent/featureListArea";
import SumbitCv from "../pageComponent/sumbitCv";
import LetTalk from "../pageComponent/letTalk";
export default function Home() {
  const bannerText = [
    {
      id: 1,
      text: "We Specializes in helping recruiters find The Perfect IT professionals For Their Team ",
    },
    {
      id: 2,
      text: "We Specializes in helping recruiters find IT professionals faster and more efficiently. ",
    },
  ];

  // Banner Text
  let bannerHeadText = bannerText.map((eachText) => {
    return (
      <div className="item">
        <h5 className="line-head">
          <span className="line before"></span>
          Talent Individuals
          <span className="line  after"></span>
        </h5>
        <h1
          className="banner-head mt-40 mb-50 mt-xs-20 mb-xs-30"
          key={eachText.id}
        >
          {eachText.text}
        </h1>
        <a href="/about" className="btn btn-square">
          Read Moree
          <i className="fas fa-long-arrow-alt-right ml-20"></i>
        </a>
      </div>
    );
  });

  return (
    <div classNameName="">
      {/* MAIN BANNER SECTION START */}
      <section
        className="slider-area"
        style={{ backgroundImage: "url('img/banner/banner_1.jpg')" }}
        data-overlay="9"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 text-center">
              <div className="slide-div">
                <OwlCarousel
                  items={1}
                  autoplay={true}
                  autoplayTimeout={5000}
                  loop={true}
                  animateOut="fadeOut"
                  smartSpeed={450}
                  className="slider-1 wow fadeInDown"
                >
                  {bannerHeadText}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* MAIN BANNER SECTION START */}

      {/************************ 
       SEARCH START
       *************************/}
      <FeaturesArea />
      {/************************ 
       SEARCH END
       *************************/}
      <section className="about-us pt-100 pb-100">
        <div className="container">
          <div className="row align-items-xl-center">
            <div className="col-lg-5">
              <div className="ceo-video relative img-lined mx-auto shadow-1 wow fadeInLeft">
                <img src="img/about/support.jpg" alt="" />
                <div className="blob green transform-center">
                  <a href="#" className="popup-video">
                    <i className="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-text mt-md-60 text-center text-lg-left wow fadeInRight">
                <div className="fancy-head left-al  mb-10">
                  <h5 className="line-head mb-10">
                    <span className="line before d-lg-none"></span>
                    It Our Job To Help You.
                    <span className="line  after"></span>
                  </h5>
                  <h1>Outsourced IT Support</h1>
                </div>
                <p>
                  We make IT support services such as IT infrastructure, Web
                  Apps Dev, cloud computing, digigtal marketing, and others. We
                  have the necessary knowledge and are fully equipped to recruit
                  skilled professionals for you.
                </p>
                <p>
                  Our outsourcing services make it easier for businesses to
                  contact specialists and IT professionals to find jobs. We also
                  offer training and courses that upgrades our recruit's skill
                  set. Talent Individual's services are cost-effective, and our
                  clients never have to worry about a service outage.{" "}
                </p>
                <div className="hr-line mb-30 mt-30"></div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="count-box wow fadeInUp">
                      <h3 className="f-900 mb-10">
                        <span className="counter">500</span>+
                      </h3>
                      <p>Happy Clients</p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="count-box wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <h3 className="f-900 mb-10">
                        <span className="counter">3591+</span>
                      </h3>
                      <p>Jobs Completed</p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="count-box wow fadeInUp"
                      data-wow-delay=".4s"
                    >
                      <h3 className="f-900 mb-10">
                        <span className="counter">5</span>+
                      </h3>
                      <p>Sponspors</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* services */}
      <Services />
      {/* services */}

      {/* FeatureListArea Start */}
      <FeatureListArea />
      {/* FeatureListArea End */}

      {/* SumbitCv Start */}
      <SumbitCv />
      {/* SumbitCv End */}

      {/* Testimonial Start*/}
      <Testimonials />
      {/* Testimonial End*/}

      {/* LetTalk Start*/}
      <LetTalk />
      {/* LetTalk End*/}
    </div>
  );
}
