import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  let year = new Date().getFullYear();

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row mb-sm-50 mb-xs-00">
          <div className="col-lg-4 z-5">
            <div className="contact-area relative h-100 mr-lg-20 mr-md-00">
              <div className="footer-logo mb-35">
                <img src="img/logo/logo_footer.png" alt="" />
              </div>
              <div className="contact-options mb-35">
                <ul>
                  <li>
                    <i className="fas fa-map-marker-alt green"></i>Unit F39
                    Water Front Studio 1 Dock Road, Canning Town, London, E16
                    1AH
                  </li>
                  <li>
                    <i className="fas fa-phone green"></i>+44 207 642 2814
                  </li>
                  <li>
                    <i className="fas fa-envelope green"></i>
                    info@talentindividuals.co.uk
                  </li>
                </ul>
              </div>
              <div className="social-links">
                <ul className="social-icons">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-google-plus-g"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-4">
            <div className="footer-links pt-85 pt-md-50 mb-sm-70">
              <h5 className="green f-700 mb-35">Quick Links</h5>
              <ul className="links-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/partners">Partners</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/team">Our Team</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-4">
            <div className="footer-links pt-85 pt-md-50 mb-sm-70">
              <h5 className="green f-700 mb-35">Career</h5>
              <ul className="links-list">
                <li>
                  <Link to="/career">Find Jobs</Link>
                </li>
                <li>
                  <Link to="/workwithus">Hire Talents</Link>
                </li>
                <li>
                  <Link to="/workwithus">Work With Us</Link>
                </li>
                <li>
                  <Link to="/signup">Register as a Talent</Link>
                </li>
                <li>
                  <Link to="/login">Login</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div className="footer-links pt-85 pt-md-50 mb-50">
              <h5 className="green f-700 mb-35">Need Help?</h5>
              <ul className="links-list">
                <li>
                  <Link to="/faq">FAQS</Link>
                </li>
                {/* <li>
                  <a href="#">Privacy</a>
                </li>
                <li>
                  <a href="#">Policy</a>
                </li> */}
                <li>
                  <Link to="/contact">Support</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="copyright pt-25 pb-25">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8">
              <p className="mb-0 white">
                © Copyrights {year} Talent Individuals.co.uk All rights reserved
              </p>
            </div>
            <div className="col-xl-4 text-right">
              <a
                href="#"
                className="btn scroll-btn f-right flex-center z-25 opacity-0"
              >
                <i className="fas fa-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}
