import React from "react";
import Jobs from "../pageComponent/jobs";
import LetTalk from "../pageComponent/letTalk";
import SubHeader from "../pageComponent/subHeader";
import AboutWorkhere from "../pageComponent/aboutWorkhere";
export default function Career() {
  return (
    <div>
      <SubHeader title={"Career"} />

      <Jobs />

      <AboutWorkhere />

      <LetTalk />
    </div>
  );
}
