import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ 
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({
        url: "/api/auth/user",
        method: "GET",
        headers: tokenConfig().headers,
      }),
      providesTags: ["User"],
    }),
    registerUser: builder.mutation({
      query: (initialPost) => ({
        url: "/api/auth/register",
        method: "POST",
        body: initialPost,
      }),
    }),
    updateUser: builder.mutation({
      query: (details) => ({
        url: "/api/auth/update",
        method: "PUT",
        body: details,
        headers: tokenConfig().headers,
      }),
      invalidatesTags: ["User"],
    }),
    loginUser: builder.mutation({
      query: (initialPost) => ({
        url: "/api/auth/login",
        method: "POST",
        body: initialPost,
        headers: tokenConfig().headers,
      }),
    }),
    resetPassword: builder.mutation({
      query: (initialPost) => ({
        url: "/api/auth/resetpassword",
        method: "POST",
        body: initialPost,
        headers: tokenConfig().headers,
      }),
    }),
    uploadCv: builder.mutation({
      query: initialPost => ({
        url: '/api/uploadcv',
        method: 'POST',
        body: initialPost,
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  }),
    }),
  })


export const tokenConfig = () => {
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

export const selectUserData = apiSlice.endpoints.getUser.select();

export const {
  useGetUserQuery,
  useRegisterUserMutation,
  useUpdateUserMutation,
  useLoginUserMutation,
  useResetPasswordMutation,
  useUploadCvMutation,
} = apiSlice;
