export default [
  {
    id: 1,
    icon: "img/feature/icon1.png",
    title: "24/7 remote network support",
    desc: "At talent Individuals, recruiters are assigned assistants to handle certain parts of the recruiting process and work in close collaboration.",
  },
  {
    id: 2,
    icon: "img/feature/icon2.png",
    title: "Technology Recruitment",
    desc: "Our services are available in over 30 countries, so we provide recruiters with highly qualified specialists and talents. Our recruitment service also includes options for long-term and short-term contracts. ",
  },
  {
    id: 3,
    icon: "img/feature/icon3.png",
    title: "Outsourcing",
    desc: "Talent Individuals provide several innovative solutions to common problems. We are making recruiting easier for our clients. ",
  },
];
