import {useState, createContext} from "react"


export const LoaderContext = createContext()

export default function LoaderWrapper({ children }){

    const [ loading, setLoading ] = useState(false) 
    

    return(
        <LoaderContext.Provider value={{loading, setLoading}}>
            {children}
        </LoaderContext.Provider>
    ) 
}