import "./App.css";
import { Outlet } from "react-router-dom";
import Header from "./Navigations/header";
import Footer from "./Navigations/footer";
import { apiSlice } from "./redux/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "./redux/api/apiSlice";
import { useNavigate } from "react-router-dom";

function App({ auth }) {
  const dispatch = useDispatch();

  const { isError } = useSelector(selectUserData);

  if (auth) {
    dispatch(apiSlice.endpoints.getUser.initiate());
  }

  if (auth && isError) {
    localStorage.removeItem("token");
  }

  return (
    <div className="App">
      <Header auth={auth} />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
