import React from "react";
import jobData from "../sitedata/data";
import { Link } from "react-router-dom";

export default function Jobs() {
  let jobDataRev = jobData.reverse()
  let AllJobs = jobDataRev.map((eachJob) => {
    return (
      <div className="col-lg-6">
        <div className="job-list d-flex align-items-center justify-content-between">
          <div className="job-detail">
            <p className="green f-500 mb-5">{eachJob.contractDuration}</p>
            <h5 className="f-700 fs-19 mb-5">
              <Link to={`/applyjob/${eachJob.jobTitle}`}>
                {eachJob.jobTitle}
              </Link>
            </h5>
            <ul className="list-inline fs-12 mb-5">
              <li className="list-inline-item pr-15">
                <i className="fas fa-map-marker-alt mr-10 fs-14 green"></i>
                {eachJob.location}
              </li>
              <li className="list-inline-item pr-15">
                <i className="fas fa-dollar-sign mr-10 fs-14 green"></i> $
                {eachJob.salary} per month
              </li>
            </ul>
          </div>
          <div className="job-apply">
            <Link
              to={`/applyjob/${eachJob.jobTitle}`}
              className="plus-btn flex-center"
            >
              <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  });
  return (
    <section className="job-list-section bg-light-white pt-90 pb-100">
      <div className="container">
        <div className="row align-items-end  mb-45">
          <div className="col-lg-7 col-md-12 text-center text-lg-left">
            <div className="fancy-head left-al">
              <h1 className="mb-sm-10">Current Open Positions</h1>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 text-center text-lg-right">
            <p className="job-found f-500 blue">
              <span className="green">{jobData.length}</span> Jobs Found
            </p>
          </div>
        </div>
        <div className="row">{AllJobs}</div>

        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="pagination-type1 center-align mt-30">
              <ul>
                <li>
                  <a href="#">
                    <i className="fas fa-long-arrow-alt-left"></i>
                  </a>
                </li>
                <li>
                  <a href="#">1</a>
                </li>
                <li>
                  <a href="#">
                    <i className="fas fa-long-arrow-alt-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
