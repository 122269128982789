import React from "react";
import ClientList from "../pageComponent/clientList";
import ExperienceCta from "../pageComponent/experienceCta";
import SubHeader from "../pageComponent/subHeader";
import LetTalk from "../pageComponent/letTalk";
export default function Contact() {
  return (
    <div>
      <SubHeader title={"Contact Us"} />

      <section className="contact-options pt-95 pb-95">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="fancy-head text-left text-md-center relative z-5 mb-40 ">
                <h5 className="line-head mb-15 ">
                  <span className="line before d-none d-md-block"></span>
                  Contact us
                  <span className="line after"></span>
                </h5>
                <h1 className="">Contact Details</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="d-flex mb-sm-30">
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <i className="fas fa-phone-volume green"></i>
                  </div>
                </div>
                <div className="icon-box-content">
                  <h5 className="f-700 fs-19 mb-10">Phone</h5>
                  <p>+44 207 642 2814</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="d-flex">
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <i className="far fa-envelope green"></i>
                  </div>
                </div>
                <div className="icon-box-content">
                  <h5 className="f-700 fs-19 mb-10">Email</h5>
                  <p>Info@talentindividuals.co.uk</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="d-flex  mt-md-30">
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <i className="fas fa-map-marker-alt green"></i>
                  </div>
                </div>
                <div className="icon-box-content">
                  <h5 className="f-700 fs-19 mb-10">Address</h5>
                  <p>
                    Unit F39 Water Front Studio 1 Dock Road, Canning Town,
                    London, E16 1AH
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="contact-form  bg-light-white pt-100 pb-100"
        style={{ backgroundImage: "url('img/bg/bg-abt.jpg')" }}
        data-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="fancy-head text-center relative z-5 mb-40">
                <h5 className="line-head mb-15 ">
                  <span className="line before "></span>
                  Send us a message
                  <span className="line after"></span>
                </h5>
                <h1 className="mb-5">Get in Touch with Us</h1>
                <p className="small-p">Send Your Enquiries To Us</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <form action="https://formspree.io/f/xzbwggab" method="POST" className="relative z-5 mt-10">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group relative mb-30 mb-sm-20">
                      <input
                        type="text"
                        className="form-control input-lg input-white shadow-5"
                        id="name"
                        placeholder="Name"
                        name="name"
                      />
                      <i className="far fa-user transform-v-center"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group relative mb-30 mb-sm-20">
                      <input
                        type="email"
                        className="form-control input-lg input-white shadow-5"
                        id="email"
                        placeholder="Email"
                        name="email"

                      />
                      <i className="far fa-envelope transform-v-center"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group relative mb-30 mb-sm-20">
                      <input
                        type="text"
                        className="form-control input-lg input-white shadow-5"
                        id="phone"
                        placeholder="Phone number"
                        name="number"
                      />
                      <i className="fas fa-mobile-alt transform-v-center"></i>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group relative mb-30 mb-sm-20">
                      <textarea
                        className="form-control input-white shadow-5"
                        name="message"
                        id="message"
                        cols="30"
                        rows="7"
                        placeholder="Your message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12 text-center mt-30">
                    <button className="btn btn-square  blob-small">
                      SUBMIT
                      <i className="fas fa-long-arrow-alt-right ml-20"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <ExperienceCta />
      <ClientList />

      <LetTalk />
    </div>
  );
}
