import React from "react";
import LetTalk from "../pageComponent/letTalk";
import SubHeader from "../pageComponent/subHeader";
import TeamArea from "../pageComponent/teamArea";

export default function Team() {
  return (
    <div>
      <SubHeader title={"Our Team"} />
      <TeamArea />
      <section
        className="how-it-works pt-100 pb-100 pt-sm-30 pb-sm-30"
        style={{ backgroundImage: "url('img/bg/bg-1.jpg')" }}
        data-overlay="9"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 text-center">
              <div className="how-it-content z-5 mt-5">
                <h1 className="white f-700 mt-35 wow fadeInDown">
                  Want to Work With Us?
                </h1>
                <p className="white mt-10 mb-40 wow fadeInDown">
                  Text content coming soon
                </p>
                <a
                  href="career.html"
                  className="btn btn-square blob-small mb-35 wow fadeInUp"
                >
                  CURRENT JOBS
                  <i className="fas fa-long-arrow-alt-right ml-20"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LetTalk />
    </div>
  );
}
