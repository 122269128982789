export default [
  {
    id: 1,
    count: "300",
    title: "Satisfied Customers",
  },
  {
    id: 2,
    count: "20",
    title: "Awards Winner",
  },
  {
    id: 3,
    count: "30",
    title: "Talents & Recruiter",
  },
  {
    id: 4,
    count: "530",
    title: "Projects Completed",
  },
];
