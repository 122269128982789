import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import testimonialsData from "../sitedata/testimonialsData";
export default function Testimonials() {
  let testimonials = testimonialsData.map((testimonial) => {
    return (
      <div className="item">
        <div className="testimonial-div text-center">
          <div className="client-image mb-5">
            <img
              src={testimonial.img}
              className="mt-10 mb-10 rounded-circle"
              alt=""
            />
          </div>
          <div className="client-texts">
            <p class="white mb-20">{testimonial.review}</p>
          </div>
          <div className="client-info">
            <ul className="stars-rate" data-starsactive="5">
              <li className=" text-center">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </li>
            </ul>
            <h6 className="client-name green f-700">{testimonial.name}</h6>
            <p className="white mb-0">{testimonial.role}</p>
          </div>
        </div>
      </div>
    );
  });
  return (
    <section
      className="testimonials pt-95 pb-100"
      data-overlay="9"
      style={{ backgroundImage: "url('img/bg/bg-2.jpg')" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="fancy-head text-center relative z-5 mb-40">
              <h5 className="line-head mb-15 ">
                <span className="line before "></span>
                Testimonials
                <span className="line after"></span>
              </h5>
              <h1 className="white">We Love Our Clients</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <OwlCarousel
              items={1}
              autoplay={true}
              autoplayTimeout={5000}
              loop={true}
              className="testimonial-slider"
            >
              {testimonials}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
}
