import React from "react";
import { FormBtn2 } from "../../components/formbtn2";
import { Input } from "../../components/formcomponents";


export default function Talentcvupload({setStep, setCv, cv, checkCv}) {

  const Validate = cv !== ""

  return (
    <section className="login pt-40 pb-40">
      <div className="container center">
        <section
          className="contact-form  bg-light-white pt-70 pb-70"
          data-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="fancy-head text-center relative z-5 mb-40">
                  <h1 className="mb-5">Upload Your CV</h1>
                  <p className="small-p">
                    Send your CV for job alerts sent straight to your inbox.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="relative z-5 mt-10">
                  <div className="row justify-content-center">
                    <div className="col-md-8 mt-5">
                      <div className="uploadcv">
                        <label htmlFor="">Select File</label>
                        <Input type="file" className="uploadcv__input" value={cv} change={setCv} name="cv" />
                        <div className="uploadcv__icon">
                          <i className="fas fa-upload"></i>
                        </div>
                      </div>
                    </div>
                    <FormBtn2 setStep={setStep} Validate={Validate} cv={cv} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
