export default [
  {
    id: 1,
    contract: "All",
  },
  {
    id: 2,
    contract: "Full-Time",
  },
  {
    id: 3,
    contract: "Part-Time",
  },
];
