import React from "react";

export default function LetTalk() {
  return (
    <section
      className="cta pt-50 pb-50"
      style={{ backgroundImage: "url('img/bg/bg_cta.jpg')" }}
      data-overlay="9"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-4 col-lg-5 mb-md-20 text-center text-lg-left">
            <h3 className="z-5 white f-700 lh-18 wow fadeInLeft">
              What type of challenge are you facing?
              <span className="green italic">Let’s talk</span>
            </h3>
          </div>
          <div className="col-xl-8 col-lg-7 text-center text-lg-right z-5">
            <a
              href="mailto:info@talentindividuals.co.uk?subject=Mail from Talentindividuals.co.uk&body=Hello TalentIndividuals,"
              className="btn btn-square-white mr-20 mr-xs-00 d-block d-sm-inline-block mb-xs-15 wow fadeInUp"
            >
              <i className="fas fa-envelope mr-15"></i>
              info@talentindividuals.co.uk
            </a>
            <a
              href="tel:+442076422814"
              className="btn btn-square-green d-block d-sm-inline-block blob-small wow fadeInUp"
            >
              <i className="fas fa-phone mr-15"></i>+44 207 642 2814
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
