import React from "react";
import featureListAreaData from "../sitedata/featureListAreaData";

export default function FeatureListArea() {
  let FeatureListAreaList = featureListAreaData.map((each, index) => (
    <li className="" key={each.id}>
      <div className="d-flex align-items-center">
        <div className="feature-num mr-25">
          <span className="flex-center bg-light-white">0{index + 1}</span>
        </div>
        <div className="feature-detail">
          <h5 className="f-700">{each.title}</h5>
          <p>{each.desc}</p>
        </div>
      </div>
    </li>
  ));
  return (
    <section className="feature-list-area pt-100 pb-100">
      <div className="container">
        <div className="row align-items-xl-center">
          <div className="col-lg-6">
            <div className="video-present relative img-lined shadow-1 bg-blue">
              <img
                src="img/feature/office_video.jpg"
                className="opacity-5 img-100"
                alt=""
              />
              <div className="video-text transform-center">
                <div className="blob green video-blob">
                  <a href="#" className="popup-video">
                    <i className="fas fa-play"></i>
                  </a>
                </div>
                <h4 className="white f-700 mt-15">Our Features</h4>
                <p className="white mt-5 mb-0">
                  Watch What How Talent Individuals is Different
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="feature-all mt-md-60">
              <div className="fancy-head left-al text-left text-md-center text-lg-left mb-30">
                <h5 className="line-head mb-15">
                  <span className="line before d-sm-none d-md-block d-lg-none"></span>
                  Why Choose Us?
                  <span className="line after"></span>
                </h5>
                <h1>Our Features </h1>
              </div>
              <div className="feature-list">
                <ul className="feature-list-all">{FeatureListAreaList}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
