export default [
  {
    id: 1,
    icon: "img/career/1.png",
    title: "Job flexibility",
    desc: "employees and employers can agree on time frames for each project/job, which allows both parties to have a flexible work schedule.",
  },
  {
    id: 2,
    icon: "img/career/2.png",
    title: "Referral Bonus",
    desc: "Talent Individuals runs a complete referral bonus system. ",
  },
  {
    id: 3,
    icon: "img/career/3.png",
    title: "Best salary rates",
    desc: "We ensure that employees get fair and appropriate wage offers from employers. We also make sure our clients get value for their money.",
  },
  {
    id: 4,
    icon: "img/career/4.png",
    title: "Celebration",
    desc: "We Celebrate every single win at Talent Individuals. no milestone is too small.",
  },
];
