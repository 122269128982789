import React from "react";

export default function Savedjobs() {
  return (
    <div className="saved-jobs col-md-5 p-5 mb-30 text-left bg-light-white img-lined shadow-1">
      <h3 className="f-700 fs-19 mb-10">You have Saved job(s)</h3>

      <div className="activities-stats">
        <a href="">View All</a>
      </div>
    </div>
  );
}
