import React from "react";
import { Link } from "react-router-dom";
export default function SubHeader({ title }) {
  return (
    <section
      className="inner-banner pt-80 pb-95"
      style={{ backgroundImage: "url('img/banner/inner-banner.jpg')" }}
      data-overlay="7"
    >
      <div className="container">
        <div className="row z-5 align-items-center">
          <div className="col-md-8 text-center text-md-left">
            <h1 className="f-700 green">{title}</h1>
            <span className="green-line bg-green d-none d-md-inline-block"></span>
          </div>
          <div className="col-md-4 text-center text-md-right">
            <nav aria-label="breadcrumb" className="banner-breadcump">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fas fa-home fs-12 mr-5"></i>Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {title}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}
