import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import About from "./pages/about";
import Contact from "./pages/contact";
import Home from "./pages/home";
import reportWebVitals from "./reportWebVitals";
import Career from "./pages/career";
import Workwithus from "./pages/workwithus";
import Partners from "./pages/partners";
import Team from "./pages/team";
import Login from "./pages/userAuthPages/login";
import SIgnUpForm from "./pages/userAuthPages/signupform";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { apiSlice } from "./redux/api/apiSlice";
import { PrivateRoute } from "./components/privateRouter";
import Dashboard from "./pages/clientdashboard/dashboard";
import LoaderWrapper from "./components/loadercontext";
import IndividualServiceLoad from "./dynamic/individualServiceLoad";
import ApplyJob from "./pages/applyJob";
import SumbitCvForJob from "./pages/sumbitCvForJob";
import Faq from "./pages/faq";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApiProvider api={apiSlice}>
      <LoaderWrapper>
        <BrowserRouter>
          <Routes>
            <Route
              path=""
              element={
                <PrivateRoute
                  element={App}
                  meta={{
                    requiresAuth: false,
                  }}
                />
              }
            >
              <Route
                path="/sumbitcvforjob/:title"
                element={
                  <PrivateRoute
                    element={SumbitCvForJob}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />

              <Route
                path="/:service"
                element={
                  <PrivateRoute
                    element={IndividualServiceLoad}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/"
                element={
                  <PrivateRoute
                    element={Home}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/about"
                element={
                  <PrivateRoute
                    element={About}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute
                    element={Dashboard}
                    meta={{
                      requiresAuth: true,
                    }}
                  />
                }
              />
              <Route
                path="/career"
                element={
                  <PrivateRoute
                    element={Career}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/workwithus"
                element={
                  <PrivateRoute
                    element={Workwithus}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/partners"
                element={
                  <PrivateRoute
                    element={Partners}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/signup"
                element={
                  <PrivateRoute
                    element={SIgnUpForm}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/login"
                element={
                  <PrivateRoute
                    element={Login}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/team"
                element={
                  <PrivateRoute
                    element={Team}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/contact"
                element={
                  <PrivateRoute
                    element={Contact}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />

              <Route
                path="/applyjob/:job"
                element={
                  <PrivateRoute
                    element={ApplyJob}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
              <Route
                path="/faq"
                element={
                  <PrivateRoute
                    element={Faq}
                    meta={{
                      requiresAuth: false,
                    }}
                  />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </LoaderWrapper>
    </ApiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
