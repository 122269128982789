import React from "react";
import { Link } from "react-router-dom";
export default function SumbitCv() {
  return (
    <section
      className="callback-area pt-95 pb-85"
      style={{ backgroundImage: "url('img/banner/banner_1.jpg')" }}
      data-overlay="9"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="fancy-head text-center relative z-5 mb-10 wow fadeInDown">
              <h5 className="line-head mb-15 white">
                <span className="line before bg-white"></span>
                Looking For the Perfect Talent For Your Team?
                <span className="line after bg-white"></span>
              </h5>
              <h1 className="white mb-20">Talk To Us</h1>
              <Link
                to="/workwithus"
                className="btn btn-square-white mr-20 mr-xs-00 d-block d-sm-inline-block mb-xs-15 wow fadeInUp"
              >
                <i className="fas fa-envelope mr-15"></i> Reach Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
