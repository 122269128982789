export default [
  {
    id: 1,
    img: "img/testimonial/client1.jpg",
    review: "Temporary requirements well understood permanent made offer",
    rating: "",
    name: "James",
    role: "Employer",
  },
  {
    id: 2,
    img: "img/testimonial/client2.jpg",
    review: "Talent Individuals me my dream job",
    rating: "",
    name: "Jessica David",
    role: "Candidate",
  },
];
