export default [
  {
    id: 1,
    contractDuration: "Full Time",
    jobTitle: "Front End Developer",
    location: "lagos, Nigeria",
    salary: "1000",
    duration: "month",
    experience: "2",
    jobDesc:
      "We are looking for a Front-End Developer with at least 2+ years of experience using React and Next.js. The ideal candidate should have strong know-how with two or more of these modern frameworks: React, React Context API, HTML5 / CSS3 and SSR (NextJS). Responsibilities will include translation of the UI/UX design wireframes to actual code that will produce visual elements of the application. You will work with the UI/UX designer and bridge the gap between graphical design and technical implementation, taking an active role on both sides and defining how the application looks as well as how it works This role requires strong communication skills and the ability to work well cross-functionally.",
    jobResponsibilities: [
      "Develop new user-facing features using React/NextJs",
      "Build reusable code and libraries for future use",
      "Ensure the technical feasibility of UI/UX designs",
      "Optimize application for maximum speed, security and scalability",
      "Translating Figma designs into reusable components",
      "Collaborate with other team members and stakeholders",
      "Troubleshoot any front-end technical or performance issues that may arise",
      "Self-manage development tasks and proactively raise issues, concerns and suggestions to the stakeholders",
    ],
    skillExperience: [
      "Experience in ReactJS is a must",
      "Experience in NextJS is a must",
      "Ensure the technical feasibility of UI/UX designs",
      "Experience in VueJS is a plus.",
      "Proficient in Javascript (even better if you know Typescript)",
      "Proficient understanding of state management frameworks such as - Redux, React Context",
      "At least 2+ years practical experience building applications with React and Next.js",
      "Good experience with HTML, CSS, JavaScript/ES6+, jQuery, etc.",
      "Experience with connecting to and retrieving data from API endpoints using REST or GraphQL",
    ],
  },
  {
    id: 2,
    contractDuration: "Full Time",
    jobTitle: "Product Designer",
    location: "Brooklyn, USA",
    salary: "856",
    duration: "month",
    experience: "3",
    jobDesc:
      "We are looking to hire a self-motivated Product Designer for our Growth team. As a Product Designer, you will be a crucial member of our exceptional high agency all-remote team to achieve our company wide mission of helping Sourcegraph become the standard tool for searching and understanding public code. You will work collaboratively with product and engineering to create a highly functional and beautiful experience that delights a technical audience.",
    jobResponsibilities: [
      "Have credible conversations with engineers & product managers about design tradeoffs.",
      "Active involvement in strategic (the “why”), not just tactical (the “how”) realities of the design decisions you make.",
      "Work closely with other designers to establish good practices and processes around our design system and visual design.",
      "Ship several updates to our sign-up, admin, and user onboarding experiences that help new users get to a wow moment sooner.",
      "Advocate for consistent use of a product design system in collaboration with engineering & product leads.",
      "Collaborate with other team members and stakeholders",
      "Troubleshoot any front-end technical or performance issues that may arise",
      "Self-manage development tasks and proactively raise issues, concerns and suggestions to the stakeholders",
    ],
    skillExperience: [
      "You have experience with usability testing, customer journey mapping, developing personas, wireframing and prototyping, sketching, and other UX design methods.",
      "You have experience building end-to-end experiences in popular design tools (we use Figma).",
      "You have the ability to adapt your tools and processes for the velocity of a fast-paced startup where projects have varying requirement levels.",
      "You have expertise in designing complex web applications as demonstrated in a strong portfolio showcasing your tried and tested process for tackling design problems, as well as high quality interaction and visual design.",
      "You demonstrate strong UI and visual design skills and a keen eye for aesthetics in the context of complex products.",
      "You have a knack for giving and receiving feedback, communicating complex concepts, and explaining the reasoning behind your designs.",
      "You have a existing understanding of developers, their needs, and motivations",
      "You have used analytics tools to ensure your designs are as effective as possible",
      "You have a existing understanding of developers, their needs, and motivations",
      "Your working hours overlap with 8am-4pm ET for at least 4 hours per week so we have time to collaborate synchronously when necessary",
    ],
  },
  {
    id: 3,
    contractDuration: "Part Time",
    jobTitle: "Digital Marketing Sales Analyst",
    location: "Abuja",
    salary: "67",
    duration: "month",
    experience: "2",
    jobDesc: 'Promote the sales activities of Mercatura Online Shop, Getting new customers to participate in our foodstuff export seminar workshop in Nigeria by trading foodstuff on Mercatura Online Shop Adept with online skills such as in social media marketing, social media presence, leverage on the power of online platforms to improve Mercaturas reveune, Be internet savvy as well as demonstrate excellent communication, and analytical skills.Work with a Business Development Manager as a team to grow the product distribution order within Nigeria. Also work closely with local farmers.',
    jobResponsibilities: [
      "Be 18years old and above.",
      "You must own an Android smartphone and can use online apps",
      "Be able to read and write and can communicate using any language to interact with shoppers",
      "The ability to work online from anywhere including at home.",
      "The ability to work in a fast-paced environment.",
      "Collaborate with other team members and stakeholders",
      "Troubleshoot any front-end technical or performance issues that may arise",
      "Self-manage development tasks and proactively raise issues, concerns and suggestions to the stakeholders",
    ],
    skillExperience: [
      "Effective communication skills and target oriented.",
      "Be able to work as a team with other staff members",
    ],
  },
    {
    id: 4,
    contractDuration: "Part Time",
    jobTitle: "Recruitment Consultant",
    location: "Remote",
    salary: "Commission Only",
    duration: "month",
    experience: "2",
    jobDesc:
      "Calling all Talented Recruiter... We are looking for future superstars who can go on the journey with us. Covering tech roles for some of the world's fastest growing companies. We are a new start-up company; At talentindividuals we specialize in helping recruiters find the perfect IT professionals for their team and we also provide IT services worldwide Joining at this stage enables a clear path to progression from Talent Sourcing through to Talent Partner and beyond - guided by a wealth of knowledge and experience spanning a range of territories, markets and talent functions. Our team knows their stuff and we proudly promote the sharing of ideas and collaboration. Getting off to a flying start and being supported through your journey is a key part of Talentindividuals culture, you'll also be expected to play a fundamental role in Talentindividuals growth too! We are a specialised recruitment agency who recruit in Information technology market space. Our company was founded in 2020 and we are a new start-up recruitment online agency with a fantastic commission and progression structure in place for self-development.",
    jobResponsibilities: [
      "Managing the entire Candidate Recruitment Process",
      "Identify & Qualify: Source and approach potential candidates in a telephone-based environment, assessing their suitability against specific client requirements",
      "Resource: Utilise job boards, internal database and social media to find high-quality professionals",
      "Proactively seeking new business opportunities & generating leads",
      "The ability to work in a fast-paced environment.",
      "Collaborate with other team members and stakeholders",
      "Sourcing and screening candidates globally",
      "Building relationships with candidates and existing/new clients",
      "Negotiating offers",
      "Updating and maintaining our in-house database",
      "Learning about technology and recruitment markets",
      "using sales, business development, marketing techniques and networking to attract business from client companies",
      "visiting clients to build and develop positive relationships",
      "developing a good understanding of client companies, their industry, what they do, their work culture and environment",
      "advertising vacancies by drafting and placing adverts",
      "using social media to advertise positions, attract candidates and build relationships",
      "headhunting - identifying and approaching suitable candidates who may already be in work",
      "using candidate databases to match the right person to the client's vacancy",
      "receiving and reviewing applications, managing interviews and tests and creating a shortlist of candidates for the client",
      "requesting references and checking the suitability of applicants before submitting their details to the client",
      "briefing the candidate about the responsibilities, salary and benefits of the job",
      "preparing CVs and correspondence to forward to clients regarding suitable applicants",
      "organising interviews for candidates as requested by the client",
      "informing candidates about the results of interviews",
      "negotiating pay and salary rates and finalising arrangements between clients and candidates",
      "offering advice to both clients and candidates on pay rates, training and career progression",
      "working towards and exceeding targets that may relate to the number of candidates placed, a value to be billed to clients or business leads generated",
      "reviewing recruitment policies to ensure effectiveness of selection techniques and recruitment programmes.",
    ],
    skillExperience: [
      "Degree educated or 6 months sales experience",
      "You will know exactly what you want to achieve and where you want to go next with a clear road map of personal and career goals",
      "You will have strong communication skills and the ability to use initiative to maintain a solution focused attitude",
      "Motivated to hit KPIs",
      "Be able to collaborate well within a team",
    ],
  },
    {
    id: 5,
    contractDuration: "All",
    jobTitle: "Sales Representative",
    location: "Remote",
    salary: "Commission Only",
    duration: "month",
    experience: "2",
    jobDesc:
      "Here at Talentindividuals we are a IT recruitment agency and we also provide IT Services worldwide, Are you just starting your career in sales and looking for an opportunity? Or perhaps you already have experience in sales and want to develop further? Do you get satisfaction from working towards a target and exceeding it? Here at Talentindividuals we will help you grow and achieve above and beyond your goals, giving you opportunities not only to earn money, but to progress. You will thrive working remotely - with flexibility of working from anywhere in the world or working your own hours - surrounded by a team of fun and driven sales people, we are  a new start-up company and We are looking for Business Development Executives to: Seek new business opportunities by contacting and developing relationships with potential customers through various channels (cold calls, follow up calls, emails and LinkedIn and social media),Use your communication skills to identify customers’ needs, Set up meetings or calls between (prospective) customers and our clients, Work as part of a team to hit targets, Be motivated, resilient and results-driven, Boost sales and contribute to both our business’ and our clients’ long-term business growth, Bring the drive and determination needed to succeed!",
    jobResponsibilities: [],
    skillExperience: ["Sales: 0 to 2 years (preferred)"],
  },
];
