import React from "react";
import { Link, useNavigate } from "react-router-dom";
import EditCredentialModal from "../../modal/editCredentialModal";
import EditPasswordModal from "../../modal/editPassword";
export default function Editcredential({user}) {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  // Open Credential Modal
  const [detailModal, setDetailModal] = React.useState(false);

  const handleCredentialModal = () => {
    setDetailModal((prevState) => !prevState);
  };

    // Open Password Modal
    const [passwordModal, setPasswordModal] = React.useState(false);

    const handlePasswordModal = () =>{
      setPasswordModal(!passwordModal)
      console.log('passowrd modal')
    }
  return (
    <>
      <div className="edit Credentials col-md-5 p-5 mb-30 text-left bg-light-white shadow-1">
        <h3 className="f-700 fs-19 mb-20">Edit Credentials</h3>
        <div className="edit-credentials">
          <div className="row justify-content-around">
            {/* THIS SECTION HANDLES EDIT MODALS*/}
            <Link to="" className="btn m-1 btn-primary" onClick={handlePasswordModal}>
              Edit Login credentials
            </Link>
            <Link to="" className="btn m-1 btn-primary" onClick={handleCredentialModal}>
              Edit Your details
            </Link>
            <button
              className="col-md-12 btn m-1 btn-primary"
              onClick={handleClick}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
      {passwordModal ? <EditPasswordModal passwordModal={passwordModal} setPasswordModal={setPasswordModal}/>: null}
      {detailModal ? <EditCredentialModal detailModal={detailModal} setDetailModal={setDetailModal} user={user} /> : null}
    </>
  );
}
