import React from "react";
import aboutWorkHereData from "../sitedata/aboutWorkHereData";

export default function AboutWorkhere() {
  let AboutWorkhereContent = aboutWorkHereData.map((each) => (
    <div className="col-lg-4 col-sm-6 text-left">
      <div className="icon-box d-flex why-work-box  wow fadeInUp">
        <div className="icon-img mr-20   mb-md-10">
          <img src={each.icon} alt="Icon" />
        </div>
        <div className="icon-text">
          <h5 className="f-700">{each.title}</h5>
          <p>{each.desc}</p>
        </div>
      </div>
    </div>
  ));
  return (
    <section className="about-working pt-90 pb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-left text-sm-center">
            <div className="fancy-head center-al mb-40 wow fadeInUp">
              <h1>About Working Here</h1>
            </div>
          </div>
        </div>
        <div className="row mb-10">{AboutWorkhereContent}</div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <a
              href="#!"
              className="btn btn-round-border popup-video wow fadeInRight"
            >
              <i className="fas fa-play mr-15"></i>Watch Our Video
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="hr-2 bg-light-white mb-60 mt-60"></div>
          </div>
          <div className="col-lg-12">
            <div className="owl-carousel owl-theme work-gallaty">
              <div className="item">
                <div className="each-gallery career-gallery">
                  <a
                    className="gallery-links"
                    href="img/gallery/gal1-big.jpg"
                    title="Gallery description here"
                  >
                    <img src="img/gallery/gal1.jpg" alt="" />
                    <div className="full-cover bg-green-op-8 transition-4">
                      <i className="fas fa-expand-arrows-alt transform-center"></i>
                    </div>
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="each-gallery career-gallery">
                  <a
                    className="gallery-links"
                    href="img/gallery/gal2-big.jpg"
                    title="Gallery description here"
                  >
                    <img src="img/gallery/gal2.jpg" alt="" />
                    <div className="full-cover bg-green-op-8 transition-4">
                      <i className="fas fa-expand-arrows-alt transform-center"></i>
                    </div>
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="each-gallery career-gallery">
                  <a
                    className="gallery-links"
                    href="img/gallery/gal3-big.jpg"
                    title="Gallery description here"
                  >
                    <img src="img/gallery/gal3.jpg" alt="" />
                    <div className="full-cover bg-green-op-8 transition-4">
                      <i className="fas fa-expand-arrows-alt transform-center"></i>
                    </div>
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="each-gallery career-gallery">
                  <a
                    className="gallery-links"
                    href="img/gallery/gal4-big.jpg"
                    title="Gallery description here"
                  >
                    <img src="img/gallery/gal4.jpg" alt="" />
                    <div className="full-cover bg-green-op-8 transition-4">
                      <i className="fas fa-expand-arrows-alt transform-center"></i>
                    </div>
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="each-gallery career-gallery">
                  <a
                    className="gallery-links"
                    href="img/gallery/gal5-big.jpg"
                    title="Gallery description here"
                  >
                    <img src="img/gallery/gal5.jpg" alt="" />
                    <div className="full-cover bg-green-op-8 transition-4">
                      <i className="fas fa-expand-arrows-alt transform-center"></i>
                    </div>
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="each-gallery career-gallery">
                  <a
                    className="gallery-links"
                    href="img/gallery/gal6-big.jpg"
                    title="Gallery description here"
                  >
                    <img src="img/gallery/gal6.jpg" alt="" />
                    <div className="full-cover bg-green-op-8 transition-4">
                      <i className="fas fa-expand-arrows-alt transform-center"></i>
                    </div>
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="each-gallery career-gallery">
                  <a
                    className="gallery-links"
                    href="img/gallery/gal1-big.jpg"
                    title="Gallery description here"
                  >
                    <img src="img/gallery/gal1.jpg" alt="" />
                    <div className="full-cover bg-green-op-8 transition-4">
                      <i className="fas fa-expand-arrows-alt transform-center"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
