import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../../redux/api/apiSlice";
import { LoaderContext } from "../../components/loadercontext";

export default function Login() {
  const navigate = useNavigate();

  const { loading, setLoading } = useContext(LoaderContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loginUser, { error }] = useLoginUserMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    try {
      const result = await loginUser({ username, password }).unwrap();
      localStorage.setItem("token", result.token);
      if (result) setLoading(loading);
      setUsername("");
      setPassword("");
      navigate("/dashboard");
    } catch (err) {
      console.log(err);
      setLoading(loading);
    }
  };

  return (
    <div>
      <section
        className="login pt-100 pb-100"
        style={{ background: "#001c6b" }}
      >
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xl-7 col-lg-6 d-none d-lg-block">
              <div
                className="login-image bg-cover h-100"
                style={{ backgroundImage: "url('img/bg/login.jpg')" }}
              ></div>
            </div>
            <div className="col-xl-5 col-lg-6 ">
              <div className="form-area bg-light-white">
                <h2 className="f-700 mb-15">Have an Account?</h2>
                <p>Enter Your Account Details to Log in</p>
                <form onSubmit={handleSubmit}>
                  <div className="form-group relative mb-25 mb-sm-20">
                    <input
                      type="text"
                      className="form-control input-lg input-white shadow-5"
                      id="name"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <i className="far fa-user transform-v-center"></i>
                  </div>
                  <div className="form-group relative mb-20 mb-sm-20">
                    <input
                      type="password"
                      className="form-control input-lg input-white shadow-5"
                      id="pwd"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <i className="fas fa-lock transform-v-center"></i>
                  </div>
                  <div className="form-group form-check pl-0">
                    <div className="d-flex justify-content-between">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          checked=""
                        />
                        <label
                          className="custom-control-label fs-13"
                          for="customCheck1"
                        >
                          <span className="label-check">Remember me</span>
                        </label>
                      </div>
                      <a href="#" className="fs-12 black">
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                  {loading ? (
                    <div className="loader_container">
                      <div class="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-square btn-block shadow-4 mt-20"
                    >
                      LOGIN
                    </button>
                  )}
                  <div className="signup-login text-center">
                    <p className="mt-15 fs-13">
                      New here?
                      <Link
                        to="/signup"
                        className="ml-5 mb-0 d-inline-block f-500"
                      >
                        Sign up
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
