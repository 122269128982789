import React from "react";
import { Link } from "react-router-dom";
import servicesData from "../sitedata/servicesData";
export default function Services() {
  let services = servicesData.map((service) => {
    return (
      <div className="col-lg-4 col-md-6" key={service.id}>
        <Link to={`/${service.title}`}>
        <div className="service-box transition-4 text-center relative img-lined mb-30">
          <div className="service-icon">
            <div className="service-icon-bg"></div>
            <img src={service.img} className="transform-center" alt="" />
          </div>
          <div className="service-text">
            <h4 className="f-700 mt-30 mb-10">
              <a href="services-details.html">{service.title}</a>
            </h4>
            <p>{service.desc}</p>
            <Link to={`/${service.title}`} className="btn btn-round mt-10">
              Learn More
            </Link>
          </div>
        </div>
        </Link>
       
      </div>
    );
  });
  return (
    <section className="services bg-light-white pt-95 pb-70 bg">
      <div className="container">
        <div className="row align-items-end  mb-45">
          <div className="col-lg-7 col-md-12 text-center text-lg-left">
            <div className="fancy-head left-al">
              <h5 className="line-head mb-15">
                <span className="line before d-lg-none"></span>
                TALENTindividuals
                <span className="line after"></span>
              </h5>
              <h1>
                When It Comes To Dependability, We Are The Company To Call!
              </h1>
              <p>
                The world is fast evolving; technology has made it possible to
                work remotely, and Talentindividuals are making it even easier!
              </p>
            </div>
          </div>
        </div>

        {/*********************
         SERVICES START
         **********************/}
        <div className="row">{services}</div>

        {/*********************
         SERVICES START
         **********************/}
      </div>
    </section>
  );
}
