import React, { useRef } from "react";
import { SelectInput } from "../components/formcomponents";
import jobtypeData from "../sitedata/jobtypeData";
import jobContracts from "../sitedata/jobContracts";

export default function ApplicationForm({
  jobType,
  setJobType,
  jobContract,
  setJobContract,
}) {
  let jobTypes = jobtypeData.map((eachJob) => (
    <option value={eachJob.jobtype} key={eachJob.id}>
      {eachJob.jobtype}
    </option>
  ));

  let contractType = jobContracts.map((eachContract) => (
    <option value={eachContract.contract} key={eachContract.id}>
      {eachContract.contract}
    </option>
  ));

  return (
    <form 
      action='https://formspree.io/f/mnqrvarg'
      method="POST"
    >
      <div className="row">
        <div className="col-md-4">
          <div className="form-group relative mb-30 mb-sm-20">
            <input
              type="text"
              className="form-control input-lg input-white shadow-5"
              id="name"
              placeholder="First Name"
              name="First Name"
            />
            <i className="far fa-user transform-v-center"></i>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group relative mb-30 mb-sm-20">
            <input
              type="text"
              className="form-control input-lg input-white shadow-5"
              id="name"
              placeholder="Name"
              name="Name"
            />
            <i className="far fa-user transform-v-center"></i>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group relative mb-30 mb-sm-20">
            <input
              type="text"
              className="form-control input-lg input-white shadow-5"
              id="phone"
              placeholder="Phone number"
              name="Phine number"
            />
            <i className="fas fa-mobile-alt transform-v-center"></i>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group relative mb-30 mb-sm-20">
            <input
              type="email"
              className="form-control input-lg input-white shadow-5"
              id="email"
              placeholder="Email"
              name="Email"
            />
            <i className="far fa-envelope transform-v-center"></i>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group relative mb-30 mb-sm-20">
            <SelectInput
              type={jobTypes}
              name="jobType"
              selected="JOB TYPE"
              change={setJobType}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group relative mb-30 mb-sm-20">
            <SelectInput
              type={contractType}
              name="JobContract"
              selected="Job Contract"
              change={setJobContract}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group relative mb-30 mb-sm-20">
            <input
              type="text"
              className="form-control input-lg input-white shadow-5"
              id="current-address"
              placeholder="Street Address"
              name="Street Address"
            />
            <i className="fas fa-mobile-alt transform-v-center"></i>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group relative mb-30 mb-sm-20">
            <input
              type="text"
              className="form-control input-lg input-white shadow-5"
              id="current-address"
              placeholder="City"
              name="City"
            />
            <i className="fas fa-mobile-alt transform-v-center"></i>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group relative mb-30 mb-sm-20">
            <input
              type="text"
              className="form-control input-lg input-white shadow-5"
              id="current-address"
              placeholder="State / Province"
              name="State / Province"
            />
            <i className="fas fa-mobile-alt transform-v-center"></i>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group relative mb-30 mb-sm-20">
            <input
              type="text"
              className="form-control input-lg input-white shadow-5"
              id="current-address"
              placeholder="Postal / Zip Code"
              name="Postal / Zip Code"
            />
            <i className="fas fa-mobile-alt transform-v-center"></i>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group relative mb-30 mb-sm-20">
            <textarea
              className="form-control input-white shadow-5"
              name="message"
              id="message"
              cols="30"
              rows="7"
              placeholder="Describe Job"
            ></textarea>
          </div>
        </div>
        <div className="col-lg-12 text-center mt-30">
          <button className="btn btn-square  blob-small mybtn">
            Sumbit<i className="fas fa-long-arrow-alt-right ml-20"></i>
          </button>
        </div>
      </div>
    </form>
  );
}
