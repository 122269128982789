import {Navigate, useLocation} from "react-router-dom"

const o = Object.create(null)

export const PrivateRoute = ({
    element: Component,
    meta = o,
    ...rest
  }) => {

        const auth = localStorage.getItem("token") ? true : false
        const { pathname } = useLocation()
        const isLoginOrRegisterPage = pathname === '/signup' || pathname === '/login'


        if(auth && isLoginOrRegisterPage){
            return <Navigate to="/" />
        }

        if(meta.requiresAuth){
            if(auth){
                return <Component {...rest} auth={auth} />
            }
            return <Navigate to="/" />
        }

        return <Component {...rest} auth={auth} />
}


